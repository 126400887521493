// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const accountEndpoint = `${backendEndpoint || ''}/account`

export const deleteUserAccountRequest = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE'
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

export const getUserAccountRequest = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

export const updateUserAccountRequest = (payload: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(payload)
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

