//  @flow

import { Spinner } from 'react-bootstrap'

import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'

import ErrorMessage from '../ErrorMessage'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import TrackingConsent from './TrackingConsent'

type Props = {
  dialog: {
    isDialogOpen: boolean
  },
  isMobile: boolean
};

// eslint-disable-next-line
export default (Component: Function) => (props: Props) => {
  const { loading, currentUser } = useKeycloakAuth()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  if (currentUser && currentUser.email_verified === false) {
    return <ErrorMessage />
  }

  if (loading) {
    return <Spinner animation='border' variant='secondary' />
  }

  return <><Component { ...{ ...props, isMobile: !matches }} /> <TrackingConsent/> </>
}
