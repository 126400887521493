// @flow

import styled from 'styled-components'

import { Container, HeaderBar, Card, PageNotReachable } from 'components/ReUsable'
import InfoSection from './content/infoSection'
import AdvancedInfoSection from './AdvancedInfoSection'
import TextSection from './content/textSection'
import { TextInformationResponse, fetchTextInformation, fetchAttributeInformation, AttributeInformationResponse, ListInformationResponse, fetchListInformation } from 'apis/advancedInfomation'
import { useCurrentDevice } from '../ManageDelegations/hooks/useCurrentDevice'
import TableSection from './content/tableSection'
import { DevicePageMainAlerts } from 'components/ReUsable/Alert/devicePageMainAlerts'
import { getProperty } from 'utils/helpers'
import { useEffect, useState } from 'react'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { PermissionCheck } from 'components/ReUsable/PermissionCheck'

const StyledCard = styled(Card)`
  height: auto;
  margin-top: 1.5rem;
  position: relative;
  border-radius: 0;
  flex: none;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
`

type Props = {
  match: any
}

type DeviceState = {
  isConnected: boolean
  lastUpdated: string
}

const AdvancedInfo = ({ match }: Props) => {
  const serialNumber = match.params.serialNumber
  const { currentDevice, deviceLoadingOrErrorComponent, alerts } = useCurrentDevice(serialNumber, true)
  const [deviceState, setDeviceState] = useState<DeviceState | null>(null)
  useEffect(() => {
    if (currentDevice) {
      setDeviceState(getProperty(currentDevice.state) || {})
    }
  }, [currentDevice])

  if (deviceLoadingOrErrorComponent) {
    return deviceLoadingOrErrorComponent
  }

  const permissions = currentDevice ? currentDevice.permissions : null
  if (!permissions) {
    return <></>
  }

  const deviceOffline = !deviceState?.isConnected
  return (
    <main role="main">
      <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ADVANCED_INFORMATION_READ]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.ADVANCED_INFORMATION_READ}
        deniedTemplate={<PageNotReachable/>}
      >
        <Container data-cy="page-advanced-info">
          {deviceState && (
            <DevicePageMainAlerts isConnected={deviceState?.isConnected || false} deviceLastUpdated={deviceState?.lastUpdated} pageDialog={alerts?.pageDialog} isPageDialogOpen={false} />
          )}
          <HeaderBar headerText="Advanced Information" backlinkText="Back to Fuel Cell" backlinkUrl={`/devices/${serialNumber}`} serialNumber={serialNumber} device={currentDevice} />
          <StyledCard>
            <AdvancedInfoSection
              header="Ethernet"
              commandName="ethernet"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <InfoSection resp={data as AttributeInformationResponse | null} loading={loading} />}
              apiCall={fetchAttributeInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Errors"
              commandName="error"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TableSection resp={data as ListInformationResponse | null} loading={loading} />}
              apiCall={fetchListInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Error Statistic"
              commandName="error-stats"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TableSection resp={data as ListInformationResponse | null} loading={loading} />}
              apiCall={fetchListInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Life"
              commandName="life"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TableSection resp={data as ListInformationResponse | null} loading={loading} />}
              apiCall={fetchListInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Warnings"
              commandName="warnings"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TableSection resp={data as ListInformationResponse | null} loading={loading} />}
              apiCall={fetchListInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="FM Errors"
              commandName="fm-errors"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TableSection resp={data as ListInformationResponse | null} loading={loading} />}
              apiCall={fetchListInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Real Time Clock"
              commandName="realtime-clock"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <InfoSection resp={data as AttributeInformationResponse | null} loading={loading} />}
              apiCall={fetchAttributeInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Log"
              commandName="log"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TextSection resp={data as TextInformationResponse | null} loading={loading} />}
              apiCall={fetchTextInformation}
              disabled={deviceOffline}
            />
            <AdvancedInfoSection
              header="Cycle Monitor"
              commandName="cycle"
              serialNumber={serialNumber}
              renderChild={(data, loading) => <TextSection resp={data as TextInformationResponse | null} loading={loading} />}
              apiCall={fetchTextInformation}
              disabled={deviceOffline}
            />
          </StyledCard>
        </Container>
      </PermissionCheck>
    </main>
  )
}

export default AdvancedInfo
