import { useEffect, useState } from 'react'
import { checkScopePermissionsFromAuth0, integrationTestRunning } from 'utils/helpers'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'

export default function useCheckAuth0ScopePermissions ({ rights }:{ rights: string[] }):{ allow: boolean } {
  //  Required for headless Cypress tests.
  const mockPermissionsVar = integrationTestRunning(process.env.REACT_APP_ENV_MODE) ? (window as any).USE_AUTH_MOCK_PERMISSIONS : ''
  const { isAuthenticated, accessToken } = useKeycloakAuth()
  const [allow, setAllow] = useState<boolean>(false)
  const show = async () => {
    const data = await checkScopePermissionsFromAuth0(rights, accessToken)
    setAllow(data)
  }
  useEffect(() => {
    show()
  }, [isAuthenticated, mockPermissionsVar])

  return {
    allow
  }
}
