// @flow
import { Container, PageTitle, Alert, ALERT_TYPE } from 'components/ReUsable'
import styled from 'styled-components'
import { CONFIGDATA_MAPPING, MultifactorAuthenticationItemType, useMultifactorAuthentication } from './hooks/useMultifactorAuthentication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'
import moment from 'moment'
import { faMobile } from '@fortawesome/pro-light-svg-icons'
import DeleteMenu from 'components/ReUsable/DeleteMenu'

const ContentDiv = styled.div`
  margin: 2rem auto 0 auto;
  padding: 1.8rem;
  background-color: var(--color-background-primary);
  width: 945px;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledPageTitle = styled(PageTitle)`
  text-align: left;
  margin-bottom: 1rem;
`

const StyledTypeHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 10px;
`

const StyledTypeHeader = styled.h4`
  margin-bottom: 0;
`

const StyledConfigureLink = styled.span`
  margin-left: 3px;
`

const StyledConfigRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  min-height: 40px;
`

const StyledDateText = styled.span`
  font-family: open-sans-regular;
`

export function MultifactorAuthentication() {
  const { loading, error, deleteMultifactorAuthentication, authenticationTypes } = useMultifactorAuthentication()
  const errorMsg = error ? error.title : 'Something went wrong.'
  const authConfigKeys = Object.keys(authenticationTypes)

  if (loading) {
    return null
  }

  if (authConfigKeys.length === 0) {
    return null
  }

  return (
    <Container>
      <ContentDiv>
        <StyledPageTitle data-cy="multifactor-header" className="mr-auto mb-3">
          Multifactor Authentication
        </StyledPageTitle>
        {error && <Alert id="multifactor-error" type={ALERT_TYPE.danger} message={errorMsg} />}
        {authConfigKeys.map((authKey: string) => (
          <MultifactorAuthenticationConfig key={authKey} configName={authKey} configArr={authenticationTypes[authKey]} onDelete={deleteMultifactorAuthentication} />
        ))}
      </ContentDiv>
    </Container>
  )
}

function MultifactorAuthenticationConfig({ configName, configArr, onDelete }: { configName: string; configArr: MultifactorAuthenticationItemType[]; onDelete: (uuid: string) => void }) {
  const actionName = configName === 'otp' ? 'CONFIGURE_TOTP' : 'webauthn-register'
  const { currentUser } = useKeycloakAuth()
  const configLink = `${currentUser?.iss}/protocol/openid-connect/auth?kc_action=${actionName}&client_id=${currentUser?.aud}&redirect_uri=${window.location.origin}/user_account&response_type=code`

  return (
    <>
      <StyledTypeHeaderRow data-cy="multifactor-config">
        <StyledTypeHeader>{CONFIGDATA_MAPPING[configName]}</StyledTypeHeader>
        <a href={configLink} data-cy="multifactor-add-new">
          <FontAwesomeIcon icon={['far', 'circle-plus'] as any} />
          <StyledConfigureLink>add new</StyledConfigureLink>
        </a>
      </StyledTypeHeaderRow>

      {configArr.length > 0 ? (
        configArr.map((configObj: MultifactorAuthenticationItemType) => (
          <StyledConfigRow key={configObj.id} data-cy="device-information">
            <div>
              <FontAwesomeIcon icon={faMobile as any} size="lg" /> {configObj.userLabel}
              <StyledDateText> - Created on: {moment(new Date(configObj.createdDate)).format('DD.MM.yyyy HH:mm')}</StyledDateText>
            </div>
            <DeleteMenu onDelete={() => onDelete(configObj.id)} text="Delete this device?" />
          </StyledConfigRow>
        ))
      ) : (
        <StyledConfigRow>
          <StyledDateText data-cy="no-config">No configuration found.</StyledDateText>
        </StyledConfigRow>
      )}
    </>
  )
}
