// @flow

import { UserManager } from 'oidc-client-ts';
import oidcConfig from 'keycloak/config/oidcConfig'
import { isServerNotAvailable } from 'helpers/errorDetection'

const windowAny = window as any

export function isErrorResponse(response: any): response is ErrorResponse {
  return (response as ErrorResponse).status !== undefined && (response as ErrorResponse).detail !== undefined && (response as ErrorResponse).type !== undefined
}

export const backendEndpoint = windowAny.REACT_APP_BACKEND_ENDPOINT ? windowAny.REACT_APP_BACKEND_ENDPOINT : process.env.REACT_APP_BACKEND_ENDPOINT
export const backendEndpointV2 = backendEndpoint?.replace('/v1', '/v2')
export const auth0Domain = windowAny.REACT_APP_AUTH0_DOMAIN ? windowAny.REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN
export const auth0ClientId = windowAny.REACT_APP_AUTH0_CLIENT_ID ? windowAny.REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  const user = await userManager.getUser();
  return user?.access_token; // Access token from stored session
}

const parseRequestOptions = (options: any, token: any) => {
  const contentType = options ? options['Content-Type'] : null
  const acceptHeader = options ? options.accept : null
  const parsedOptions = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...options
  }

  if (contentType) {
    parsedOptions.headers['Content-Type'] = contentType
    delete parsedOptions['Content-Type']
  }

  if (acceptHeader) {
    parsedOptions.headers.accept = acceptHeader
    delete parsedOptions.accept
  }

  return parsedOptions
}

const BLOB_CONTENT_TYPES = ['application/zip', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv;charset=UTF-8']

export type ErrorResponse = {
  type: string
  status: number
  title?: string
  detail?: string
  instance?: string
  message?: string
}

export const getResponseBody = async (requestOptions: any, response: any) => {
  const contentType = response.headers.get('Content-Type')
  if (BLOB_CONTENT_TYPES.includes(contentType)) {
    // eslint-disable-next-line no-return-await
    return await response.blob()
  }

  // TODO: it would be nice if we follow the http code conventions, eg: 201 with no body response
  // response body can be null in some cases
  try {
    return await response.json()
  } catch (SyntaxError) {
    //  there is no response body. All custom generated error responses should have a body.
    //  In this case we create a minimum custon error response in front end.
    //  eslint-disable-next-line no-return-await
    return { status: response?.status, type: response?.type }
  }
}

export const requestOptions = async (options: any) => {
  const token = await getAccessToken()

  const updatedValues = parseRequestOptions(options, token)
  return Promise.resolve(updatedValues)
}

export const executeRequest = (url: string, searchValue?: string, sorting?: any, options?: any, filter?: string) => {
  if (searchValue) {
    url = `${url}&search=${searchValue}`
  }

  if (sorting) {
    url = url.concat(`&sort=${sorting.sortKey},${sorting.sortDirection || 'asc'}`)
  }

  if (filter) {
    url = url.concat(filter)
  }

  return requestOptions(options).then((updatedRequestOptions) =>
    fetch(url, updatedRequestOptions)
      .then((response) => {
        if (response.ok) {
          return getResponseBody(options, response)
        }
        //  Throw response error body, it is handled on catch block.
        throw getResponseBody(options, response)
      })
      .then((data) => data)
      .catch((error) => {
        //  Backend is not available. Throw the error coming back from fetch.
        if (isServerNotAvailable(error)) {
          throw error
        }
        //  Return the error. Specific endpoint or the component can handle it.
        return error
      })
  )
}

export const executeRequestSWR = async (url: string, searchValue?: string, sorting?: any, options?: any, filter?: string) => {
  if (searchValue) {
    url = `${url}&search=${searchValue}`
  }

  if (sorting) {
    url = url.concat(`&sort=${sorting.sortKey},${sorting.sortDirection || 'asc'}`)
  }

  if (filter) {
    url = url.concat(`&filter=${encodeURIComponent(filter)}`)
  }

  const updatedRequestOptions = await requestOptions(options)
  const res = await fetch(url, updatedRequestOptions)
  //  Error
  if (!res.ok) {
    //  Try to parse the error as JSON
    //  API returns in case of an error an object of type ErrorResponse.
    //  In this case we create an error object with data prop as ErrorResponse and status prop res.status
    //  If API is not reached .json() will throw an error. In this case we create an error object
    //  with status prop and data is null.
    const errorResponse = await res.json().catch(() => null)
    // Throw an error with both the status and the parsed error response
    const error = new Error('API Error')
    ;(error as any).status = res.status
    ;(error as any).data = errorResponse as ErrorResponse | null
    //  Error thrown to inform useSWR hook.
    throw error
  }
  //  Success.
  return res.json()
}

export const addFileObjectToForm = (fileName: string, fileSelector: string, data: any) => {
  const upload = document.querySelector(fileSelector)

  if (upload instanceof HTMLInputElement && upload.files) {
    data.append(fileName, upload.files[0])
  }
}

// Exact replica of executeRequest() method with the only change in how the error is thrown only for the Group Management feature.
// Error should be thrown back to the handlers and should be adopted for other scenarios.
// This method can be removed once all handler functions have adapted to error response thrown from catch statement. Additionally all Hooks should also handle error from catch statements.
export const executeGroupApiRequest = (url: string, searchValue?: string, sorting?: any, options?: any, filter?: string) => {
  if (searchValue) {
    url = `${url}&search=${searchValue}`
  }

  if (sorting) {
    url = url.concat(`&sort=${sorting.sortKey},${sorting.sortDirection || 'asc'}`)
  }

  if (filter) {
    url = url.concat(`&filter=${encodeURIComponent(filter)}`)
  }

  return requestOptions(options).then((updatedRequestOptions) =>
    fetch(url, updatedRequestOptions)
      .then((response) => {
        if (response.ok) {
          return getResponseBody(options, response)
        }
        //  Throw response error body, it is handled on catch block.
        throw getResponseBody(options, response)
      })
      .then((data) => data)
      .catch((error) => {
        //  Backend is not available. Throw the error coming back from fetch.
        if (isServerNotAvailable(error)) {
          throw error
        }
        //  Return the error. Specific endpoint or the component can handle it.
        return error.then((errorResponse) => {
          throw errorResponse
        })
      })
  )
}
