// @flow

import { useState, useRef, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { StyledTextInput } from '../FuelCartridges/fuelCartridgesEditDialog'
import { ALERT_TYPE, Alert } from 'components/ReUsable'
import { getTerminalCommand } from 'apis/devices'
import { TERMINAL_USER } from 'appConstants/devices'

const ContentDiv = styled.div`
  padding: 0px 50px 25px;
`

const InputForm = styled(Form.Group)`
  margin-top: 3rem;
`

export const TerminalDash = styled.textarea`
  font-family: 'Courier New', monospace;
  height: 300px;
  width: auto;
  max-height: 550px;
  overflow-y: scroll !important;
  background: black;
  color: lime;
  padding: 20px;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.1);
  caret-color: lime;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::selection {
    background-color: var(--color-background-primary);
    color: black;
  }
`

type TerminalDialogProps = {
  serialNumber: string
}

export const TerminalDialog = ({ serialNumber }: TerminalDialogProps) => {
  const TerminalDashRef = useRef<HTMLTextAreaElement>(null)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [terminalDashCommand, setTerminalDashCommand] = useState(TERMINAL_USER)

  useEffect(() => {
    TerminalDashRef.current?.setAttribute('spellcheck', 'false')
    if (TerminalDashRef.current) {
      TerminalDashRef.current.style.height = '700px'
      TerminalDashRef.current.style.width = '100%'
    }
  }, [])

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  const validateCommand = (command: string) => {
    return command.length < 1024
  }

  const terminalDashCommandChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const enteredCommand = e.target.value
    const lastIndexOfSFC = enteredCommand.lastIndexOf(TERMINAL_USER)
    const commandStripped = enteredCommand.split('SFC> ').at(-1) || ''
    if (!validateCommand(commandStripped)) {
      setError('Command is too long!')
    } else {
      setError('')
    }

    if (!enteredCommand.startsWith(TERMINAL_USER)) {
      setTerminalDashCommand(TERMINAL_USER)
    } else {
      setTerminalDashCommand((prev) => {
        if (prev.lastIndexOf(TERMINAL_USER) !== lastIndexOfSFC) {
          return prev
        }
        return enteredCommand
      })
    }
  }

  const handleEnterKey = async () => {
    const commandStripped = terminalDashCommand.split('SFC> ').at(-1) || ''
    const textArea = TerminalDashRef.current

    if (!validateCommand(commandStripped)) {
      return
    }
    if (commandStripped.toLowerCase().trim() === 'clear') {
      setTerminalDashCommand(TERMINAL_USER)
      return
    }
    if (commandStripped.trim() === '') {
      setTerminalDashCommand((prevOutput) => prevOutput + '\n' + '\n' + TERMINAL_USER)
      if (textArea) {
        setTimeout(() => {
          textArea.scrollTop = textArea.scrollHeight
        }, 0)
      }
      return
    }
    try {
      const res = await getTerminalCommand(serialNumber, { command: commandStripped, password })
      if (!res || (res?.status && res.status > 200)) {
        setError(`Error Code: ${res.status} - ${res.detail}`)
        return
      }
      let formattedResponse = ''
      formattedResponse = `\n${res.response && res.response.replace(/\r/g, '\n')}\n${TERMINAL_USER}`
      setTerminalDashCommand((prevOutput) => prevOutput + formattedResponse)
      if (textArea) {
        setTimeout(() => {
          textArea.scrollTop = textArea.scrollHeight
        }, 0)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const terminalDashKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const caretStart = e.currentTarget.selectionStart
    const prefix = TERMINAL_USER
    const prefixLength = prefix.length
    const prefixLastLetterPosition = terminalDashCommand.lastIndexOf(prefix) + prefixLength

    if (e.key === 'Backspace' && caretStart <= prefixLastLetterPosition) {
      e.preventDefault()
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      handleEnterKey()
    }
  }

  return (
    <>
      <ContentDiv>
        <Form id="terminal-form">
          {error ? <Alert message={error} type={ALERT_TYPE.danger} /> : null}
          <InputForm as={Row}>
            <Form.Label column sm="2">
              Password
            </Form.Label>
            <Col>
              <StyledTextInput name="password" type="password" variant="outlined" className="form-control" value={password} placeholder="Optional" onChange={handlePasswordChange}></StyledTextInput>
            </Col>
          </InputForm>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Response
            </Form.Label>
            <Col>
              <TerminalDash ref={TerminalDashRef} value={terminalDashCommand} onKeyDown={terminalDashKeydown} onChange={terminalDashCommandChange} name="terminal" />
            </Col>
          </Form.Group>
        </Form>
      </ContentDiv>
    </>
  )
}

export default TerminalDialog
