// @flow

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionProps, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import { PageTitle, Container, Row, Col, Text, PaginateIt, Button, PermissionCheck, Dialog, Alert, ALERT_TYPE, OutlineButton, TrashButton } from 'components/ReUsable'
import {
  fetchUserGroups,
  createUserGroups,
  fetchOneUserGroup,
  updateUserGroup,
  deleteUserGroup,
  leaveUserGroup,
  deleteUserGroupMembership,
  clearOneUserGroup,
  addNewGroup,
  cancelAddGroup,
  updateGroupsPagination,
  deleteUserGroupInvite
} from 'actions'
import { getProperty, bindDispatch } from 'utils/helpers'
import GroupManagementEditDetails from './manageEditDetails'
import { GRANTED, GROUPS_PERMISSIONS_LIST } from 'appConstants'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'
import HeaderBackButton from 'components/ReUsable/HeaderBackButton'
import { StyledButtonPanel } from '../Device'

const ContentDiv = styled.div`
  padding: 3rem 0;
`
const StyledAccordion = styled(Accordion)`
  margin: 0 0 6.5px;
  object-fit: contain;
  border-radius: 2.5px !important;
  background-color: var(--color-table-even-row) !important;
  box-shadow: none !important;
  font-family: DigitalSerialBold;
  font-size: 13px;
  & .MuiCollapse-root.MuiCollapse-entered {
    background-color: var(--color-background-primary);
  }
  :before {
    display: none;
  }
  & .MuiAccordionDetails-root {
    border-top: ${(props: AccordionProps & { isnew: boolean }) => (props.isnew ? 'none' : '0.5px solid var(--color-accordion-table-border)')};
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  align-self: stretch;
  justify-content: center;
  padding: 0px !important;
  & span {
    overflow-wrap: anywhere;
  }
  &.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    display: ${(props: any) => (props.isnew ? 'none' : '')};
  }
  & .MuiAccordionSummary-content {
    align-self: stretch;
    margin: 0px;
    & div {
      :first-child {
        padding-left: 12px;
        @media (max-width: 991px) {
          & > div {
            padding-left: 0px;
          }
        }
      }
      color: var(--color-text);  
      justify-content: center;
      @media (min-width: 991px) {
        :not(:last-child) {
          border-right: 0.5px solid var(--color-accordion-table-border);
        }
        :first-child {
          flex: 1;
          user-select: text;
          cursor: default;
        }
        :nth-child(2) {
          flex: 2;
          padding-left: 12px;
          user-select: text;
          cursor: default;
        }
      }
      @media (max-width: 991px) {
        border-right: 0.5px solid var(--color-accordion-table-border);
      }
    }
    & .flex-icon {
      flex: 0.5;
      @media (max-width: 991px) {
        animation: mobileIconsAnimation 0.5s ease-in;
        align-items: center;
      }
    }
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
`

const StyledGroupHeader = styled(Row)`
  background-color: var(--color-table-header);
  border-radius: 2.5px;
  border: solid 0.5px var(--color-background-secondary);
  margin-bottom: 9px;
  margin-top: 22px;
  color: var(--color-lightest);
  text-transform: uppercase;
  & .group-header-col {
    padding: 10px 0px 10px 0px;
    font-size: 13px;
    :first-child {
      flex: 1;
      padding-left: 12px;
      border-right: solid 0.5px var(--color-background-secondary);
    }
    :nth-child(2) {
      flex: 2;
      padding-left: 12px;
      border-right: solid 0.5px var(--color-background-secondary);
    }
    :not(:first-child):not(:nth-child(2)) {
      flex: 0.5;
      align-items: center;
      flex-wrap: wrap;
      border-right: solid 0.5px var(--color-background-secondary);
    }
    :last-child {
      border-right: none !important;
    }
  }
`
const StyledIconCol = styled(Col)`
  &:hover,
  &.selected {
    background-color: var(--color-background-primary);
  }
`
const StyledAddGroupElement = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4em;
`

const StyledPageTitle = styled(PageTitle)`
  margin-top: 0.8rem;
`

const StyledSpinner = styled(Spinner)`
  display: ${(props: { display: boolean }) => (props.display ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`

const StyledText = styled(Text)`
  margin: 0 auto;
`

const BACK_LINK_TEXT = 'Back to EFOY Fuel Cells'

const initialState = (state) => ({
  groups: state?.groups,
  currentGroup: state?.groups?.current,
  addSuccess: state?.success?.group?.add.success,
  deleteSuccess: state?.success?.group?.delete.success,
  groupErrors: state?.errors?.group,
  updateSuccess: state?.success?.group?.update.success,
  leaveSuccess: state?.success?.group?.leave.success
})

const useBoundActions = () => {
  const dispatch = useDispatch()
  const actions = {
    fetchUserGroups: bindDispatch(dispatch)(fetchUserGroups),
    createUserGroups: bindDispatch(dispatch)(createUserGroups),
    fetchOneUserGroup: bindDispatch(dispatch)(fetchOneUserGroup),
    updateUserGroup: bindDispatch(dispatch)(updateUserGroup),
    deleteUserGroup: bindDispatch(dispatch)(deleteUserGroup),
    leaveUserGroup: bindDispatch(dispatch)(leaveUserGroup),
    deleteUserGroupMembership: bindDispatch(dispatch)(deleteUserGroupMembership),
    clearOneUserGroup: bindDispatch(dispatch)(clearOneUserGroup),
    addNewGroup: bindDispatch(dispatch)(addNewGroup),
    cancelAddGroup: bindDispatch(dispatch)(cancelAddGroup),
    updateGroupsPagination: bindDispatch(dispatch)(updateGroupsPagination),
    deleteUserGroupInvite: bindDispatch(dispatch)(deleteUserGroupInvite)
  }

  return actions
}

export function Groups () {
  const { groups, currentGroup, addSuccess, deleteSuccess, groupErrors, updateSuccess, leaveSuccess } = useSelector(initialState)

  const groupsContent = getProperty(groups)
  const [expanded, setExpanded] = useState<any>(false)
  const [isAdding, setIsAdding] = useState(false)
  const [showMobileOptionsForGroup, setShowMobileOptionsForGroup] = useState('')
  const [showConfirmGroupDeleteDialog, setShowConfirmGroupDeleteDialog] = useState({ value: false, slug: '' })
  const [showConfirmLeaveGroupDialog, setShowConfirmLeaveGroupDialog] = useState({ value: false, slug: '' })
  const [showAlert, setShowAlert] = useState<any>({ value: false, message: '', type: '' })
  const [showErrorText, setShowErrorText] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { currentUser: user } = useKeycloakAuth()

  const history = useHistory()

  const {
    fetchUserGroups,
    createUserGroups,
    fetchOneUserGroup,
    updateUserGroup,
    deleteUserGroup,
    leaveUserGroup,
    deleteUserGroupMembership,
    clearOneUserGroup,
    addNewGroup,
    cancelAddGroup,
    updateGroupsPagination,
    deleteUserGroupInvite
  } = useBoundActions()

  useEffect(() => {
    fetchUserGroups({
      page: groups?.pageable?.pageNumber,
      loading: true
    })
  }, [])

  useEffect(() => {
    if (addSuccess) {
      setShowAlert({ value: true, message: 'Group was added successfully.', type: ALERT_TYPE.success })
      setIsAdding(false)
    }
  }, [addSuccess])

  useEffect(() => {
    if (deleteSuccess) {
      setShowAlert({ value: true, message: 'Group was deleted successfully.', type: ALERT_TYPE.success })
    }
  }, [deleteSuccess])

  useEffect(() => {
    if (leaveSuccess) {
      setShowAlert({ value: true, message: 'You left the group.', type: ALERT_TYPE.success })
    }
  }, [leaveSuccess])

  useEffect(() => {
    if (isAdding) {
      window.scrollTo(0, window.innerHeight)
    }
  }, [isAdding])

  // if groupErrors has an attribute, then show the alert
  useEffect(() => {
    if (groupErrors?.operation === 'group') {
      setShowAlert({ value: true, message: groupErrors.message || 'Error regarding loading the group details', type: ALERT_TYPE.danger })
      setShowErrorText(true)
    }
  }, [groupErrors])

  if (!groups || groups?.loading) return <Spinner animation="border" variant="secondary" />

  const handleExpandAccordian = (val: any) => {
    if (val === expanded) {
      setExpanded(false)
      clearOneUserGroup()
    } else {
      if (isAdding) {
        setIsAdding(false)
        cancelAddGroup()
      }
      setExpanded(val)
      fetchOneUserGroup(val)
      showErrorText && setShowErrorText(false)
    }
    setShowAlert({ value: false, message: '', type: '' })
  }

  const handleMobileGroupOptionsForGroup = (id) => {
    setShowMobileOptionsForGroup(id)
  }

  const handleDelete = (slug) => {
    setShowConfirmGroupDeleteDialog({ value: true, slug: slug })
  }

  const handleAddGroup = () => {
    addNewGroup(user)
    setExpanded('')
    setIsAdding(true)
    setShowAlert({ value: false, message: '', type: '' })
  }

  const handleCancelAddNewGroup = () => {
    cancelAddGroup()
    setIsAdding(false)
    setShowAlert({ value: false, message: '', type: '' })
  }

  const handleLeaveGroup = (slug) => {
    setShowConfirmLeaveGroupDialog({ value: true, slug })
  }

  const handleUserGroupUpdate = (updatedGroup) => {
    updateUserGroup({
      updatedGroup,
      page: groups.currentPage
    })
  }

  const handleDeleteUserGroupMembership = (groupUser: { slug: string; email: string }) => {
    const payload = {
      groupUser,
      page: groups.currentPage
    }
    deleteUserGroupMembership(payload)
  }

  const handleDeleteUserGroupInvite = (invitedUser: { slug: string; email: string }) => {
    deleteUserGroupInvite({
      invitedUser,
      page: groups.currentPage
    })
  }

  return (
    <Container>
      <ContentDiv>
        {showAlert.value ? <Alert type={showAlert.type} message={showAlert.message} /> : null}
        <Row>
          <Col style={{ flexDirection: 'row' }}>
            <HeaderBackButton
              handleButtonClick={() =>
                history.push({
                  pathname: '/'
                })
              }
              backlinkText={BACK_LINK_TEXT}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledPageTitle>Group Management</StyledPageTitle>
          </Col>
          <Col style={{ justifyContent: 'center', alignItems: 'end' }}>
            {groupsContent?.length > 9 ? (
              <Button style={{ marginBottom: '20px' }} onClick={() => handleAddGroup()}>
                Add Group
              </Button>
            ) : null}
            <Text>Please note that the Group ID is sorted alphabetically.</Text>
          </Col>
        </Row>
        <PaginateIt
          page={groups.currentPage}
          totalPages={groups.totalPages}
          firstPage={groups.first}
          lastPage={groups.last}
          jumpToPage={(payload: any) => {
            fetchUserGroups({
              page: payload
            })
            updateGroupsPagination(payload)
            setIsAdding(false)
          }}
          showLegend={false}
          hideTopNavigation={true}
        >
          {groupsContent?.length > 0 ? (
            <>
              {matches ? (
                <StyledGroupHeader>
                  <Col className="group-header-col">Group ID</Col>
                  <Col className="group-header-col">Group Description</Col>
                  <Col className="group-header-col">Exit</Col>
                  <Col className="group-header-col">Edit</Col>
                  <Col className="group-header-col">Delete</Col>
                </StyledGroupHeader>
              ) : null}
              {matches &&
                groupsContent.map((group: any, i: number) => (
                  <StyledAccordion key={i} expanded={expanded === group.slug} TransitionProps={{ unmountOnExit: true }} isnew={group.isNew}>
                    <StyledAccordionSummary id={`group-management-${group.slug}`} {...{ isnew: group.isNew }}>
                      <Col>
                        <span>{group.slug}</span>
                      </Col>
                      <Col>
                        <span>{group.name}</span>
                      </Col>
                      <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_LEAVE} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_LEAVE]}>
                        <StyledIconCol className="flex-icon" alignItems="center" onClick={() => handleLeaveGroup(group.slug)}>
                          <img src={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_LEAVE] === GRANTED ? '/imgs/Door_green.svg' : '/imgs/Door_grey.svg'} alt="Leave group" />
                        </StyledIconCol>
                      </PermissionCheck>
                      <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_MODIFY} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_MODIFY]}>
                        <StyledIconCol className={`flex-icon ${expanded === group.slug ? 'selected' : ''}`} alignItems="center" onClick={() => handleExpandAccordian(group.slug)}>
                          <img src={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_MODIFY] === GRANTED ? '/imgs/Stift_green.svg' : '/imgs/Stift_grey.svg'} alt="Edit group" />
                        </StyledIconCol>
                      </PermissionCheck>
                      <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_DELETE} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_DELETE]}>
                        <StyledIconCol className="flex-icon" alignItems="center" onClick={() => handleDelete(group.slug)}>
                          <TrashButton color={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_DELETE] === GRANTED ? 'var(--color-primary)' : '#A9AAAA'} padding="3" label="Remove group"/>
                        </StyledIconCol>
                      </PermissionCheck>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      {currentGroup ? (
                        <GroupManagementEditDetails
                          deleteUserGroupMembership={handleDeleteUserGroupMembership}
                          updateUserGroup={handleUserGroupUpdate}
                          createUserGroups={createUserGroups}
                          cancelAddGroup={handleCancelAddNewGroup}
                          deleteUserGroupInvite={handleDeleteUserGroupInvite}
                        />
                      ) : (
                        <Col>
                          <StyledSpinner display={!showErrorText} animation="border" variant="secondary" />
                          {showErrorText && <StyledText>Cannot showcase content</StyledText>}
                        </Col>
                      )}
                    </AccordionDetails>
                  </StyledAccordion>
                ))}
              {/* ------ Mobile Version ------- */}
              {!matches &&
                groupsContent.map((group, i) => {
                  return (
                    <StyledAccordion key={i} expanded={expanded === group.slug} TransitionProps={{ unmountOnExit: true }} isnew={group.isNew}>
                      <StyledAccordionSummary id={`group-management-${group.slug}`} {...{ isnew: group.isNew }}>
                        <Col style={{ flexGrow: 2 }}>
                          <div style={{ overflowWrap: 'anywhere' }}>{group.name}</div>
                          <div style={{ paddingLeft: 0 }}>{group.slug}</div>
                        </Col>
                        {showMobileOptionsForGroup && showMobileOptionsForGroup === group.slug ? (
                          <>
                            <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_LEAVE} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_LEAVE]}>
                              <Col className="flex-icon" alignItems="center" onClick={() => handleLeaveGroup(group.slug)}>
                                <img src={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_LEAVE] === GRANTED ? '/imgs/Door_green.svg' : '/imgs/Door_grey.svg'} alt="Leave group" />
                              </Col>
                            </PermissionCheck>
                            <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_MODIFY} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_MODIFY]}>
                              <Col className="flex-icon" alignItems="center" onClick={() => handleExpandAccordian(group.slug)}>
                                <img src={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_MODIFY] === GRANTED ? '/imgs/Stift_green.svg' : '/imgs/Stift_grey.svg'} alt="Edit group" />
                              </Col>
                            </PermissionCheck>
                            <PermissionCheck permission={GROUPS_PERMISSIONS_LIST.GROUP_DELETE} value={group.permissions[GROUPS_PERMISSIONS_LIST.GROUP_DELETE]}>
                              <Col className="flex-icon" alignItems="center" onClick={() => handleDelete(group.slug)}>
                                <TrashButton color="var(--color-primary)" padding="3" label="Remove group"/>
                              </Col>
                            </PermissionCheck>
                          </>
                        ) : (
                          <Col className="flex-icon" alignItems="center" style={{ flexGrow: 1 }} onClick={() => handleMobileGroupOptionsForGroup(group.slug)}>
                            <FontAwesomeIcon icon={['far', 'ellipsis'] as any} />
                          </Col>
                        )}
                      </StyledAccordionSummary>
                      <AccordionDetails>
                        {currentGroup ? (
                          <GroupManagementEditDetails
                            deleteUserGroupMembership={handleDeleteUserGroupMembership}
                            updateUserGroup={handleUserGroupUpdate}
                            createUserGroups={createUserGroups}
                            cancelAddGroup={handleCancelAddNewGroup}
                            deleteUserGroupInvite={handleDeleteUserGroupInvite}
                          />
                        ) : null}
                      </AccordionDetails>
                    </StyledAccordion>
                  )
                })}{' '}
            </>
          ) : (
            'No Content'
          )}
          {!isAdding ? (
            <StyledAddGroupElement>
              <Button onClick={() => handleAddGroup()}>Add Group</Button>
            </StyledAddGroupElement>
          ) : null}
        </PaginateIt>
      </ContentDiv>
      <Dialog
        id="remove-user-from-group-dialog"
        className="modal-page"
        title={'Remove user'}
        show={showConfirmGroupDeleteDialog.value}
        onClose={() => setShowConfirmGroupDeleteDialog({ value: false, slug: '' })}
      >
        <Col alignItems="center">
          <Text textAlign="center">Are you sure you want to remove this group?</Text>
          <StyledButtonPanel>
            <OutlineButton type="button" id="cancel" color="primary" onClick={() => setShowConfirmGroupDeleteDialog({ value: false, slug: '' })}>
              CANCEL
            </OutlineButton>
            <Button
              onClick={() => {
                deleteUserGroup(showConfirmGroupDeleteDialog.slug)
                setShowConfirmGroupDeleteDialog({ value: false, slug: '' })
              }}
            >
              Confirm
            </Button>
          </StyledButtonPanel>
        </Col>
      </Dialog>

      <Dialog id="leave-group-dialog" className="modal-page" title={'Leave group'} show={showConfirmLeaveGroupDialog.value} onClose={() => setShowConfirmLeaveGroupDialog({ value: false, slug: '' })}>
        <Col alignItems="center">
          <Text textAlign="center">Are you sure you want to leave this group?</Text>
          <StyledButtonPanel>
            <OutlineButton type="button" id="cancel" color="primary" onClick={() => setShowConfirmLeaveGroupDialog({ value: false, slug: '' })}>
              CANCEL
            </OutlineButton>
            <Button
              onClick={() => {
                leaveUserGroup(showConfirmLeaveGroupDialog.slug)
                setShowConfirmLeaveGroupDialog({ value: false, slug: '' })
              }}
            >
              Confirm
            </Button>
          </StyledButtonPanel>
        </Col>
      </Dialog>
    </Container>
  )
}

export default Groups
