import {
  backendEndpoint,
  executeRequest
} from '.'

const mfaConfigEndpoint = `${backendEndpoint || ''}/account/mfa-config`

export const deleteMultifactorAuthenticationRequest = (uuid: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'DELETE'
  }

  return executeRequest(`${mfaConfigEndpoint}/${uuid}`, undefined, undefined, options)
}

export const getMultifactorAuthenticationRequest = () => {
  const options = {
      'Content-Type': 'application/json',
      method: 'GET'
  }

  return executeRequest(`${mfaConfigEndpoint}`, undefined, undefined, options)
}
