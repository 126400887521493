import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getEvents, renderTitleWithStyles, findOutEventTypeBasedOnHoverColor } from 'components/ReUsable/Charts/utils/utils'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setZoom, setNewRef } from 'actions'

import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const StyledContainerBox = styled(Container)`
  height: 100%;
  background-color: var(--color-lightest);
`

export const StyledHiddenDiv = styled.div`
  height: 24px;
  padding: 2px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: digital-serial;
  color: var(--color-text);
  z-index: 1;
  cursor: pointer;
`

const zoomState = (state) => ({
  xExtremes: state.graphReducer.xExtremes
})

// eslint-disable-next-line
const HistoryGraph = ({ deviceType, graphEvents, historyData, _toggleGraphScale, _adjustDateRange }) => {
  const historyChartRef = useRef<HighchartsReact.RefObject>(null)
  const [originalZoomCordination, setOriginalZoomCordination] = useState<any>(null)
  const { xExtremes } = useSelector(zoomState)
  const labelMessageRef = useRef<HTMLDivElement | null>(null)

  // redux actions
  const dispatch = useDispatch()
  const _setZoom = (payload: any) => dispatch(setZoom(payload))
  const _setNewRef = (payload: any) => dispatch(setNewRef(payload))

  useEffect(() => {
    _setNewRef({ currentRef: historyChartRef })
  }, [historyChartRef])

  useEffect(() => {
    if (originalZoomCordination === null && historyChartRef?.current?.chart) {
      setOriginalZoomCordination({
        xMin: historyChartRef.current.chart.xAxis[0].min as number,
        xMax: historyChartRef.current.chart.xAxis[0].max as number,
        yMin: historyChartRef.current.chart.yAxis[0].min as number,
        yMax: historyChartRef.current.chart.yAxis[0].max as number,
        y2Min: historyChartRef.current.chart.yAxis[1].min as number,
        y2Max: historyChartRef.current.chart.yAxis[1].max as number
      })
    }

    if (xExtremes && historyChartRef.current) {
      historyChartRef.current.chart.xAxis[0].setExtremes(xExtremes[0], xExtremes[1], false)
      historyChartRef?.current?.chart.redraw()
    }
  }, [xExtremes])

  const syncZoom = (e: any) => {
    const thisChart = e.target.chart
    const xAxis = thisChart.xAxis[0]
    _setZoom({ xExtremes: [xAxis.min as number, xAxis.max as number] })
  }

  const customResetZoom = () => {
    if (historyChartRef.current) {
      // Custom behavior before resetting zoom
      // Reset zoom programmatically
      // historyChartRef.current.chart.zoomOut()
      // Or manually reset extremes to null
      _setZoom({ xExtremes: [originalZoomCordination.xMin as number, originalZoomCordination.xMax as number] })
      historyChartRef.current.chart.xAxis[0].setExtremes(originalZoomCordination.xMin, originalZoomCordination.xMax, false)
      // set yExtremes to yMin and yMax
      historyChartRef.current.chart.yAxis[0].setExtremes(originalZoomCordination.yMin, originalZoomCordination.yMax, false)
      historyChartRef.current.chart.yAxis[1].setExtremes(originalZoomCordination.y2Min, originalZoomCordination.y2Max, false)
      historyChartRef.current.chart.redraw()
      // hide zoom reset button
      historyChartRef.current.container.current?.querySelector('.highcharts-reset-zoom')?.classList.add('hidden')
    }
  }

  // UseEffect to add event listener to the plot line label after the component mounts
  useEffect(() => {
    const chart = historyChartRef.current?.chart
    const plotLines = historyChartRef?.current?.chart?.xAxis[0]?.options?.plotLines

    if (chart && plotLines) {
      // Get the plot line label element by class or a custom selector
      const labelElements = chart.container.querySelectorAll('.highcharts-mouseover-label')
      // Add an event listener to the plot line label
      labelElements.forEach((element, idx) => {
        const { eventType, color, date } = findOutEventTypeBasedOnHoverColor(element as HTMLElement, plotLines[idx])
        element.addEventListener('mouseover', () => {
          if (labelMessageRef?.current) {
            labelMessageRef.current.innerText = `${eventType} - ${element.textContent} - ${date}`
            // change color
            labelMessageRef.current.style.color = color
          }
        })

        element.addEventListener('mouseout', () => {
          if (labelMessageRef?.current) {
            labelMessageRef.current.innerText = ''
          }
        })
      })
    }
  }, [historyChartRef?.current?.chart?.xAxis[0]?.options?.plotLines])

  const createHistoryOptions = (title, devicetype, events, series, _toggleGraphScale, _adjustDateRange) => {
    const options: Highcharts.Options = {
      title: {
        text: title,
        align: 'left',
        useHTML: true
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          connectNulls: false,
          states: {
            hover: {
              enabled: true,
              lineWidth: 5
            }
          },
          label: {
            connectorAllowed: false
          },
          lineWidth: 4,
          marker: {
            enabled: false // symbols on the graph series
          },
          point: {
            events: {}
          },
          gapSize: 3600, // 1 day in milliseconds
          gapUnit: 'value', // Use 'value' to specify gap in terms of time,
          dataGrouping: {
            enabled: false
          }
        }
      },
      xAxis: {
        title: {
          text: ''
        },
        crosshair: true,
        labels: {
          distance: 10,
          rotation: 0
        },
        width: '100%',
        type: 'datetime',
        plotLines: getEvents(events, devicetype),
        events: {
          afterSetExtremes: (e) => {
            // zoomCharts(e, historyChartRef.current)
            syncZoom(e)
            historyChartRef?.current?.container?.current?.querySelector('.highcharts-reset-zoom')?.classList.remove('hidden')
            if (e.min && e.max) {
              const oneDay = 24 * 60 * 60 * 1000
              if (e.max - e.min < oneDay) {
                _adjustDateRange({ _startDate: e.min, _endDate: e.max })
                _toggleGraphScale('MINUTES')
              } else {
                _adjustDateRange({ _startDate: e.min, _endDate: e.max })
                _toggleGraphScale('HOURS')
              }
            }
          }
        }
      },
      yAxis: [
        {
          title: {
            style: {
              fontFamily: 'open-sans-regular'
            },
            align: 'middle',
            rotation: 270,
            text: series && `${renderTitleWithStyles(series, 0)}`
          },
          labels: {
            distance: 20,
            format: '{value}'
          },
          lineWidth: 1, // This line makes the Y-axis bar visible
          // gray color
          lineColor: 'rgba(80, 80, 80, 0.5)' // Sets the color of the Y-axis line (red in this case)
        },
        {
          title: {
            text: series && `${renderTitleWithStyles(series, 1)}`,
            style: {
              fontWeight: 'bold',
              fontFamily: 'open-sans-regular'
            },
            align: 'middle'
          },
          opposite: true,
          labels: {
            distance: 12,
            format: '{value}'
          },
          lineWidth: 1, // This line makes the Y-axis bar visible
          // gray color
          lineColor: 'rgba(80, 80, 80, 0.5)' // Sets the color of the Y-axis line (red in this case)
        }
      ],
      series: series,
      responsive: {
        rules: [
          {
            condition: {},
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                useHTML: true,
                itemStyle: {
                  fontSize: '12px',
                  fontFamily: 'open-sans-regular'
                }
              },
              xAxis: {
                labels: {
                  // format: '{value:%H:%M}'
                }
              },
              yAxis: {
                labels: {}
              }
            }
          }
        ]
      },
      chart: {
        type: 'spline',
        height: 400,
        zooming: {
          type: 'xy',
          mouseWheel: true
        },
        resetZoomButton: {},
        events: {
          selection: function (event) {
            if (event.resetSelection) {
              // Custom behavior when the "Reset Zoom" button is clicked
              customResetZoom()
              // Optionally perform additional actions here
              return false // Prevent default zoom-out action
            }
          },
          load: function () {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const chart = historyChartRef?.current?.chart
            // Customize the appearance or behavior of the default "Reset Zoom" button
          }
        }
      },
      credits: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
      tooltip: {
        shared: true,
        distance: 30,
        useHTML: true,
        style: {
          fontSize: '14px',
          fontFamily: 'open-sans-regular'
        },
        headerFormat: '<div class="tooltip-component highcharts-tooltip-box"><table><tr><th colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' + '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '<tr class="event-warning hidden"><td>Event</td><td>{point.key}</td></tr></table></div>'
      },
      time: {
        useUTC: false
      }
    }
    return options
  }

  return (
    <>
      <StyledContainerBox>
        <StyledHiddenDiv ref={labelMessageRef}></StyledHiddenDiv>
      </StyledContainerBox>
      <HighchartsReact
        highcharts={Highcharts}
        ref={historyChartRef}
        options={{
          ...createHistoryOptions('History', deviceType, graphEvents, historyData, _toggleGraphScale, _adjustDateRange)
        }}
      />
    </>
  )
}

export default HistoryGraph
