import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'
import { useEffect, useState } from 'react'
import { checkScopePermissionsFromAuth0 } from 'utils/helpers'

export default function useAdminRoute ({ rights, path }:{ rights: string[], path: string }):{ pageAvailable: boolean, routeLoading: boolean } {
  const { isAuthenticated, accessToken } = useKeycloakAuth()
  const [pageAvailable, setPageAvailable] = useState<boolean>(false)
  const [routeLoading, setRouteLoading] = useState<boolean>(true)
  const show = async () => {
    const data = await checkScopePermissionsFromAuth0(rights, accessToken || "")
    setPageAvailable(data)
    setRouteLoading(false)
  }

  useEffect(() => {
    show()
  }, [isAuthenticated, path])

  return {
    pageAvailable, routeLoading
  }
}
