import * as C from 'appConstants'

export const handleRequestDeleteUserAccount = () => ({ type: C.REQUEST_DELETE_USER_ACCOUNT })

export const populateDeleteUserAccount = (payload: any) => ({ type: C.POPULATE_DELETE_USER_ACCOUNT, payload: payload })

export const handleRequestGetUserAccount = () => ({ type: C.REQUEST_GET_USER_ACCOUNT })

export const populateGetUserAccount = (payload: any) => ({ type: C.POPULATE_GET_USER_ACCOUNT, payload: payload })

export const handleRequestUpdateUserAccount = (payload: any) => ({ type: C.REQUEST_UPDATE_USER_ACCOUNT, payload: payload })

export const populateUpdateUserAccount = (payload: any) => ({ type: C.POPULATE_UPDATE_USER_ACCOUNT, payload: payload })

export const populateUpdateUserAccountPassword = (payload: any) => ({ type: C.POPULATE_UPDATE_USER_ACCOUNT_PASSWORD, payload: payload })
