// @flow

import { useState, useEffect } from 'react'
import { getMultifactorAuthenticationRequest, deleteMultifactorAuthenticationRequest } from 'apis/multifactorAuthentication'

import { ErrorResponse } from 'declarations/@types/alltypes'

export type MultifactorAuthenticationItemType = {
  id: string,
  userLabel: string,
  createdDate: string,
}

export type MultifactorAuthenticationType = {
  otp: MultifactorAuthenticationItemType[],
  webauthn: MultifactorAuthenticationItemType[]
}

const emptyData: MultifactorAuthenticationType = {otp: [],webauthn: []}

export const CONFIGDATA_MAPPING: {otp: string, webauthn: string} = {otp: 'One time password authentication', webauthn: 'Passkey Registration'}

export function useMultifactorAuthentication () {
  const [authenticationTypes, setAuthenticationTypes] = useState<MultifactorAuthenticationType>(emptyData)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<ErrorResponse | null>(null)
  //  Multifactor Authentication GET API call.
  const getMultifactorAuthentication = async () => {
    const mfaConfigResponse: any = await getMultifactorAuthenticationRequest()
    if (mfaConfigResponse?.status >= 400) {
      handleErrorCase(mfaConfigResponse)
      setAuthenticationTypes(emptyData)
      return false
    }
    setLoading(false)
    setSaving(false)

    if(Object.keys(mfaConfigResponse).length === 0) {
      setAuthenticationTypes({ ...emptyData })
      return true
    }

    //  Fixing the issue, that API does not return 1 item arrays.
    const otpResp = mfaConfigResponse.otp || [] 
    const webauthnResp = mfaConfigResponse.webauthn || [] 

    setAuthenticationTypes({ ...emptyData, otp: otpResp, webauthn: webauthnResp })
    return true
  }

  //  Multifactor Authentication DELETE API call.
  const deleteMultifactorAuthentication = async (uuid: string) => {
    setSaving(true)
    const tokensResponse: any = await deleteMultifactorAuthenticationRequest(uuid)

    if (tokensResponse?.status >= 400) {
      handleErrorCase(tokensResponse)
      return false
    }
    await getMultifactorAuthentication()
    return true
  }

  const handleErrorCase = (responseObj: any) => {
    try {
      setError(responseObj as ErrorResponse)
      throw new Error()
    } catch (e) {
      return false
    } finally {
      setLoading(false)
      setSaving(false)
    }
  }

  useEffect(() => {
    getMultifactorAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    error,
    authenticationTypes,
    loading,
    saving,
    deleteMultifactorAuthentication
  }
}
