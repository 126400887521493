import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects'

import * as C from 'appConstants'

import {
  populateDeleteUserAccount,
  populateGetUserAccount,
  populateUpdateUserAccount,
  genericError
} from 'actions'

import {
  deleteUserAccountRequest,
  getUserAccountRequest,
  updateUserAccountRequest
} from 'apis/usersAccount'

export function * deleteUserAccount () {
  try {
    const response = yield call(deleteUserAccountRequest)
    yield put(populateDeleteUserAccount({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

export function * getUserAccount () {
  try {
    const response = yield call(getUserAccountRequest)
    yield put(populateGetUserAccount({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateUserAccount ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(updateUserAccountRequest, payload)
    yield put(populateUpdateUserAccount({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}


export default function * usersAccountSaga () {
  yield takeLatest(C.REQUEST_DELETE_USER_ACCOUNT, deleteUserAccount)
  yield takeLatest(C.REQUEST_GET_USER_ACCOUNT, getUserAccount)
  yield takeLatest(C.REQUEST_UPDATE_USER_ACCOUNT, updateUserAccount)
  }
