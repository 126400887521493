// @flow

import React, { useState, useLayoutEffect } from 'react'
import { Route } from 'react-router-dom'

import { integrationTestRunning } from 'utils/helpers'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'

import NotificationModal from '../Notification'
import StaticHome from '../StaticHome'
import AuthGuard from './authGuard'

type Props = {
  component: React.ElementType
  path: string
  rest?: any
  exact?: boolean
}

export default function PrivateRoute ({ component: Component, path, ...rest }: Props) {
  const { isAuthenticated } = useKeycloakAuth()
  const [session, setSession] = useState<string | null>(null)

  useLayoutEffect(() => {
    setSession(localStorage.getItem('session'))
  }, [])

  if (!session && !integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return <StaticHome />
  }

  const render = (props: any) => {
    if (isAuthenticated || integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
      return (
        <>
          {/** On admin pages, we do not add <NotificationModal/> it is only for website users. */}
          {path && path.indexOf('/admin') === -1 ? <NotificationModal /> : <></>}
          <AuthGuard {...props} component={Component}/>
        </>
      )
    } else {
      return <AuthGuard {...props} />
    }
  }
  return <Route path={path} render={render} {...rest} />
}