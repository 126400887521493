import Highcharts from 'highcharts'

// Extend the Point interface to include the highlight function
declare module 'highcharts' {
  interface Point {
    highlight(event?: Event): void
  }
  interface Chart {
    syncExtremes?: any // Add the property here with appropriate type if known
  }
}

Highcharts.Pointer.prototype.reset = function () {
  return undefined
}
/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair
 */
Highcharts.Point.prototype.highlight = function () {
  this.onMouseOver() // Show the hover marker
}

// export the enhancements done
export default Highcharts
