// @flow

import { connect } from 'react-redux'

import styled from 'styled-components'

import { handleRequestDeleteUserAccount } from 'actions'

import Button from 'components/ReUsable/Button'
import RequestErrors from './requestErrors'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'

const DeleteAccountContent = styled.div.attrs(props => ({
  className: props.className
}))`
  margin: auto;
  text-align: center;
`

const DeleteAccountButtonsSection = styled.div`
  margin: auto;
  margin-bottom: 20px;
`

const LoggedOutContent = styled.p`
  margin: 0 auto;
`

type Props = {
  userAccount?: {
    deleteUserAccountAction?: {
      deletionSuccess: boolean,
      deletionErrorMessage: string
    }
  },
  handleRequestDeleteUserAccount?: () => void,
  togglePageDialog?: () => void
}

export function DeleteAccountDialogContent (props: Props) {
  const { logout } = useKeycloakAuth()
  const deleteUserAccountAction = props.userAccount ? props.userAccount.deleteUserAccountAction : undefined
  const { handleRequestDeleteUserAccount } = props

  const onDeleteUserAccount = () => {
    if(handleRequestDeleteUserAccount) {
      handleRequestDeleteUserAccount()
      logout()
    }
  }


  return (
    <DeleteAccountContent className='form-row'>
      <RequestErrors deleteUserAccountAction={ deleteUserAccountAction } />
      {
        deleteUserAccountAction?.deletionSuccess
          ? <LoggedOutContent>You are now being logged out.</LoggedOutContent>
          : <>
            <div className='form-group col-md-12'>
              Do you really want to delete this user account?
            </div>
            <br/>
            <DeleteAccountButtonsSection>
              <Button
                type='submit' id={ 'delete-user-account-confirmation' } className='btn-add-device'
                onClick={ onDeleteUserAccount }
              >
                YES
              </Button>
            </DeleteAccountButtonsSection>
          </>
      }
    </DeleteAccountContent>
  )
}

const mapState = state => (
  {
    userAccount: state.userAccount
  }
)

const mapDispatch = {
  handleRequestDeleteUserAccount
}

export default connect(
  mapState,
  mapDispatch
)(DeleteAccountDialogContent)
