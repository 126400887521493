// @flow

import { Route } from 'react-router-dom'

import { withAuthenticationRequired } from 'react-oidc-context'
import { Loading } from 'components/ReUsable'
import { integrationTestRunning } from 'utils/helpers';

export const LAST_CALLED_URL = 'last-called-url';

function AuthGuard(props: any) {
  const { component: Component, path, ...rest } = props

  return <Route path={path} render={(renderProps: any) => <Component {...renderProps} />} {...rest} />
}

//  We skip Authentication HOC in case of Cypress tests running.
const AuthGuardComponent = integrationTestRunning(process.env.REACT_APP_ENV_MODE) 
  ? AuthGuard 
  : withAuthenticationRequired(AuthGuard, {
    OnRedirecting: () => <Loading />,
    onBeforeSignin: () => {
      sessionStorage.setItem(LAST_CALLED_URL, window.location.pathname + window.location.search + window.location.hash)
    }
  });

export default AuthGuardComponent;

