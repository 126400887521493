import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { min, differenceInDays, isAfter, getUnixTime, addDays, startOfMinute, addMonths } from 'date-fns'
import { Spinner } from 'react-bootstrap'
import { NEW_API_ACTIVE } from 'featureToggles'
import { Button, Col, Gap, LargeCardSection, PermissionCheck, Row, DatePicker } from 'components/ReUsable'
import { getTelemetryEvents, getTelemetryHistory, emptyTelemetryHistoryAndEvents, toggleGraphScale, adjustDateRange, setZoom } from 'actions'
import { DEVICE_DETAILS_PERMISSIONS_LIST, GRANTED } from 'appConstants'
import { defaultStartDateVal } from 'reducers/graphReducer'

import HighchartsReact from 'highcharts-react-official'

import usePageVisibility from '../hooks/usePageVisibility'

// Local Imports
import { processHistoryData, processMultiSenseArray } from 'components/ReUsable/Charts/utils/utils'
import Highcharts from 'components/ReUsable/Charts/chartsModulesFunc'
// Enhanced Version of Highcharts
import { createIosOptions } from 'components/ReUsable/Charts/config/ios'
import HistoryGraph from '../../../ReUsable/Charts/HistoryGraph'
import MultisenseGraph from '../../../ReUsable/Charts/MultisenseGraph'

const StyledDatePickerDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`

const StyledSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: var(--chart-text-color);
  margin-top: 1rem;
`

const StyledDiv = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: left;
  color: var(--chart-text-color);
  margin-top: 1rem;
  background-color: var(--color-background-primary);
  box-sizing: border-box;
  padding: 10px;
`

const StyledDivTitle = styled.div`
  font-family: 'open-sans-regular';
  font-size: 1.3rem;
  top: 0;
  left: 0;
  color: var(--chart-text-color);
  font-weight: bold;
`
const StyledDivText = styled.div`
  font-family: 'open-sans-regular';
  font-size: 12px;
  color: var(--chart-text-color);
  font-weight: 400;
`

const DateSelectorLabel = styled.span`
  color: var(--color-text);

  @media (prefers-color-scheme: dark) {
    color: var(--color-text-light);
  }
`

const CustomDivHolder = styled.div`
  position: relative;
`

const CustomSpinner = styled(Spinner)`
  position: absolute;
  right: -2.25rem;
  top: 1rem;
  bottom: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;

  @media (max-width: 990px) {
    right: -2.25rem;
    top: 1rem;
  }
`

type Props = {
  serialNumber: string
  togglePageDialog: () => void
  permissions: any
  deviceType: string
  getMultisenseData: (serialNumber: string, timeScale: string, startTimestamp: number, endTimestamp: number) => Promise<any>
}

type TimeScale = 'HOURS' | 'MINUTES'

type ScaleSettings = {
  timeScale: TimeScale
  maxDays: number
}

const currentMinute = startOfMinute(new Date())

const graphState = (state) => ({
  graphScale: state.graphReducer.graphScale,
  _startDate: state.graphReducer._startDate,
  _endDate: state.graphReducer._endDate,
  _maxDate: state.graphReducer._maxDate,
  _defaultStartDate: state.graphReducer._defaultStartDate,
  chartRefs: state.graphReducer.chartRefs
})

const telemetryState = (state) => ({
  telemetryHistory: state.devices.telemetryHistory,
  telemetryEvents: state.devices.telemetryEvents
})

const History = (props: Props) => {
  const { graphScale, _startDate, _endDate, _maxDate, chartRefs } = useSelector(graphState)
  const { telemetryHistory, telemetryEvents } = useSelector(telemetryState)

  const renderCount = useRef(false)
  const [isPageVisible, updateDependencies] = usePageVisibility()
  const dispatch = useDispatch()
  const _toggleGraphScale = (payload: string) => dispatch(toggleGraphScale(payload))
  const _adjustDateRange = (payload: any) => dispatch(adjustDateRange(payload))
  const _setZoom = (payload: any) => dispatch(setZoom(payload))
  const _getTelemetryHistory = (payload: any) => dispatch(getTelemetryHistory(payload))
  const _getTelemetryEvents = (payload: any) => dispatch(getTelemetryEvents(payload))
  const _emptyTelemetryHistoryAndEvents = () => dispatch(emptyTelemetryHistoryAndEvents())

  const { serialNumber, togglePageDialog, permissions, deviceType, getMultisenseData } = props

  // _startDate should be 2 days before the maxEndDate and it is fixed at midnight
  const [variant, setVariant] = useState<string | null>(null)

  const [historyData, setHistoryData] = useState<any>([])
  const [graphEvents, setGraphEvents] = useState<any>([])

  const [multisenseGraphData, setMultisenseGraphData] = useState<any>(null)
  const [periodSeries, setPeriodSeries] = useState<any>([])
  const [closedPortsArray, setClosedPortsArray] = useState<any>([])
  const [showSpinner, setShowSpinner] = useState(true)

  // Refs
  const containerRef = useRef(null)
  const IoChartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const initiateTelemetryHistory = (_startDate: Date, _endDate: Date, scaleSettings: ScaleSettings) => {
    const maxDays = scaleSettings.maxDays
    const size = scaleSettings.timeScale === 'MINUTES' ? maxDays * 24 * 60 : maxDays * 24
    _getTelemetryHistory({
      serialNumber: serialNumber,
      startTimestamp: getUnixTime(_startDate) * 1000,
      endTimestamp: getUnixTime(_endDate) * 1000,
      timeScale: graphScale.timeScale,
      size: size
    })
  }

  const initiateTelemetryEvents = (_startDate: Date, _endDate: Date) => {
    _getTelemetryEvents({
      serialNumber: serialNumber,
      startTimestamp: getUnixTime(_startDate),
      endTimestamp: getUnixTime(_endDate)
    })
  }

  const feedHistoryData = () => {
    if (telemetryHistory?.response?.timestamps?.length > 0 || !telemetryHistory?.response?.timestamps) {
      setTimeout(() => {
        // adding a bit of delay for avoiding flickering behavior, this to run only if we fall into an issue with the spinner not stopping.
        setShowSpinner(false)
      }, 1000)
    }
    if (telemetryHistory && telemetryEvents && telemetryEvents.response.content && telemetryHistory.response) {
      const history = telemetryHistory.response
      if (history[Object.keys(history)[0]].length > 0) {
        const historySeries = processHistoryData(history)
        setHistoryData(historySeries)
        setGraphEvents(telemetryEvents.response.content)
      } else {
        setHistoryData([])
        setGraphEvents([])
      }
    }
  }

  const multiSenseData = async () => {
    const result = await getMultisenseData(serialNumber, graphScale.timeScale, getUnixTime(_startDate) * 1000, getUnixTime(_endDate) * 1000) // 1000 is to convert to milliseconds
    const { variant } = result
    if (variant) setVariant(variant)
    const [processedMultisenseArray, periods, closedPortsArray] = processMultiSenseArray(result.ports, variant)
    setMultisenseGraphData(processedMultisenseArray)
    setPeriodSeries(periods)
    setClosedPortsArray(closedPortsArray)
  }

  const triggerApiCalls = (_startDate: Date, _endDate: Date, scaleSettings: ScaleSettings) => {
    const days = Math.abs(differenceInDays(_startDate, _endDate))
    const gatedEndDate = min([_endDate, currentMinute])

    if (days > 1 && scaleSettings.timeScale === 'MINUTES') {
      _toggleGraphScale('HOURS')
    }

    if (isAfter(gatedEndDate, _startDate)) {
      initiateTelemetryHistory(_startDate, _endDate, scaleSettings)
      initiateTelemetryEvents(_startDate, _endDate)
    }
    setShowSpinner(true)
    // toggle off the spinner
    setTimeout(() => {
      setShowSpinner(false)
    }, 500)
  }

  const handleStartDateChange = (date) => {
    const selectedDateObj = new Date(date)
    const selectedDate = selectedDateObj.getTime()
    let selectedEndDateObj = new Date(_endDate)
    let selectedEndDate = selectedEndDateObj.getTime()
    // Compare the two timestamps to check if they are more than a month apart
    const daysApart = differenceInDays(selectedEndDateObj, selectedDateObj)
    if (daysApart > 30) {
      selectedEndDateObj = addMonths(selectedDateObj, 1)
      selectedEndDate = Math.min(selectedEndDateObj.getTime(), new Date().getTime())
    } else {
      selectedEndDate = Math.min(addMonths(selectedDateObj, 1).getTime(), new Date().getTime())
    } // If it is less then one month set current date or if selected date is more then one month in the past set selectedDate+1 month
    setShowSpinner(true)
    // adjustDateRange
    _adjustDateRange({ _startDate: selectedDate, _endDate: selectedEndDate })
    _setZoom({ xExtremes: [selectedDate, selectedEndDate] })
  }

  const handleEndDateChange = (date) => {
    const selectedDateObj = new Date(date)
    const selectedDate = selectedDateObj.getTime()
    let startDateObj = new Date(_startDate)
    let startDate = startDateObj.getTime()

    // Compare the two timestamps to check if they are more than a month apart
    const daysApart = differenceInDays(selectedDateObj, startDateObj)
    if (daysApart > 30) {
      startDateObj = addMonths(selectedDateObj, -1)
      startDate = startDateObj.getTime()
    }
    setShowSpinner(true)
    _adjustDateRange({ _startDate: startDate, _endDate: selectedDate })
    _setZoom({ xExtremes: [startDate, selectedDate] })
  }

  useEffect(() => {
    // this is for when you trigger changeVisibilityEvent therefor we know the updateDependencies is true
    if (updateDependencies && isPageVisible) {
      triggerApiCalls(_startDate, _endDate, graphScale)
      multiSenseData()
    }
  }, [isPageVisible, updateDependencies])

  useEffect(() => {
    // this for when you change the date range therefor we know the renderCount is different than 0 and whenever you change, you update call the api
    if (renderCount.current) {
      triggerApiCalls(_startDate, _endDate, graphScale)
      multiSenseData()
    }
  }, [graphScale.timeScale, _startDate, _endDate])

  useEffect(() => {
    // we can check for telemetryHistory?.payload?.serialNumber to see which device is triggered with calls
    if (telemetryEvents?.response && telemetryHistory?.response && renderCount.current) {
      feedHistoryData()
    }
  }, [telemetryEvents?.response, telemetryHistory?.response])

  // initial Render
  useEffect(() => {
    // this is when you initially render the component therefor we know the updateDependencies is TRUE + it is the first render
    // at the moment it re-renders twice so the graph takes the correct data and not takes the previous device data
    if (!renderCount.current) {
      renderCount.current = true
      triggerApiCalls(_startDate, _endDate, graphScale.timeScale)
      multiSenseData()
    }
  }, [])

  useEffect(() => {
    return () => {
      _emptyTelemetryHistoryAndEvents()
      if (renderCount.current) {
        renderCount.current = false
        _adjustDateRange({ _startDate: defaultStartDateVal, _endDate: Date.now() })
        _setZoom({ xExtremes: [defaultStartDateVal, Date.now()] })
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const synchronizeHover = (e, chart) => {
      Highcharts.each(Highcharts.charts, function (otherChart) {
        if (otherChart && otherChart !== chart) {
          const point = otherChart.series[0].searchPoint(chart.pointer.normalize(e), true)
          if (point) {
            point.onMouseOver()
          }
        }
      })
    }

    const resetHover = () => {
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart) {
          chart.tooltip.hide()
          chart.series.forEach((series) => {
            series.points.forEach((point) => point.setState(''))
          })
        }
      })
    }

    chartRefs?.forEach((chart) => {
      chart?.current?.chart?.container?.addEventListener('mousemove', (e) => {
        synchronizeHover(e, chart.current.chart)
      })
      chart?.current?.chart?.container?.addEventListener('mouseleave', resetHover)
    })

    return () => {
      chartRefs?.forEach((chart) => {
        chart?.current?.chart?.container?.removeEventListener('mousemove', (e) => {
          synchronizeHover(e, chart.current.chart)
        })
        chart?.current?.chart?.container?.removeEventListener('mouseleave', resetHover)
      })
    }
  }, [chartRefs])

  return (
    <LargeCardSection>
      <Row margin="1rem 0">
        <Col base="1px"></Col>
        <Gap></Gap>
        <Col justifyContent="center">
          <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ}>
            <StyledDatePickerDiv>
              <>
                <Row alignItems="center">
                  <Col>
                    <DateSelectorLabel>Select Date: </DateSelectorLabel>
                  </Col>
                </Row>
                <DatePicker
                  name="startDate"
                  // selected new Date(- 3 days)
                  selected={_startDate}
                  timeInputLabel="Time:"
                  timeFormat="HH:mm"
                  showTimeSelect={true}
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeIntervals={60}
                  onChange={handleStartDateChange}
                  maxDate={new Date()}
                  placeholderText="Select Start Date"
                  disabled={props.permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ] !== GRANTED}
                  dataCy="start-date-picker"
                >
                  <span>Maximum selectable range is {graphScale.maxDays} days.</span>
                </DatePicker>
                <CustomDivHolder>
                  <DatePicker
                    name="endDate"
                    selected={_endDate}
                    timeInputLabel="Time:"
                    timeFormat="HH:mm"
                    showTimeSelect={true}
                    endDate={_maxDate}
                    minDate={addDays(_startDate, 1)}
                    maxDate={_maxDate}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeIntervals={60}
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    disabled={props.permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ] !== GRANTED}
                    dataCy="end-date-picker"
                  >
                    <span>Maximum selectable range is {graphScale.maxDays} days.</span>
                  </DatePicker>
                  {showSpinner ? <CustomSpinner animation="border" variant="secondary" /> : null}
                </CustomDivHolder>
              </>
            </StyledDatePickerDiv>
          </PermissionCheck>
        </Col>
        <Gap></Gap>
        <Col base="1px" alignItems="flex-end">
          <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_EXPORT]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_EXPORT}>
            <>
              <input type="hidden" value={_startDate} id="from-date-history" />
              <input type="hidden" value={_endDate} id="to-date-history" />
              <Button onClick={() => togglePageDialog()} className="align-self-end" id="export-xlsx-button">
                export xlsx
              </Button>
            </>
          </PermissionCheck>
        </Col>
      </Row>
      <Row margin="10px">
        <Gap></Gap>
        <Gap></Gap>
      </Row>
      {/* ID-1 Here it ends */}
      <div id="container" ref={containerRef}>
        {historyData && historyData.length > 0 ? (
          <HistoryGraph {...{ deviceType, graphEvents, historyData, _toggleGraphScale, _adjustDateRange }} />
        ) : (
          <StyledDiv data-cy="no-history-data">
            <StyledDivTitle>History</StyledDivTitle>
            <StyledDivText>There is no data available. Please choose a different time period.</StyledDivText>
          </StyledDiv>
        )}
        {NEW_API_ACTIVE &&
          (multisenseGraphData && multisenseGraphData.length > 0 ? (
            <MultisenseGraph {...{ deviceType, graphEvents, multisenseGraphData }} />
          ) : (
            variant !== 'none' && (
              <StyledDiv data-cy="no-multisense-data">
                <StyledDivTitle>MultiSense Analog/Temperature</StyledDivTitle>
                <StyledDivText>There is no data available for the MultiSense analog/temperature ports. Please check the MultiSense configuration or choose a different time frame.</StyledDivText>
              </StyledDiv>
            )
          ))}
        {NEW_API_ACTIVE &&
          (periodSeries && periodSeries.length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...createIosOptions(IoChartComponentRef, 'IOs', '', graphEvents, periodSeries)
              }}
              ref={IoChartComponentRef}
            />
          ) : (
            variant === 'multi_sense_8' && (
              <StyledDiv>
                <StyledDivTitle>MultiSense IOs</StyledDivTitle>
                <StyledDivText>There is no data available for the MultiSense IO ports. Please check the MultiSense configuration or choose a different time frame.</StyledDivText>
              </StyledDiv>
            )
          ))}
        <Col base="1px" alignItems="flex">
          {NEW_API_ACTIVE && closedPortsArray && closedPortsArray.length >= 1 && (
            <StyledSpan>
              Ports currently not configured: &nbsp;
              {closedPortsArray?.map((ele, idx) => {
                if (ele.unconfigured) {
                  return (
                    <span key={Math.random().toString(26).slice(2)}>
                      &nbsp;{`${ele.name.toString().toUpperCase().trim()}`}
                      {idx === closedPortsArray.length - 1 ? '' : ','}
                    </span>
                  )
                }
              })}
            </StyledSpan>
          )}
        </Col>
      </div>
    </LargeCardSection>
  )
}

export default History
