// @flow
import { useState, useEffect } from 'react'
import { useKeycloakAuth } from 'keycloak/useKeycloakAuth'
import { getFirstMatchedAdministrationPermissionFromAuth0 } from 'utils/helpers'
import CustomNavLink from './customNavLink'

type Props = {
  className: string,
  LinkClassName: string,
  url?: string,
  exact?: boolean,
  linkText?: string,
  toggleDialog: () => void
}

export default function NavBarList (props: Props) {
  const [firstMatchingAdminPermission, setFirstMatchingAdminPermission] = useState<string | null>(null)
  const { isAuthenticated, accessToken } = useKeycloakAuth()

  useEffect(() => {
    const show = async () => {
      const matchingData = await getFirstMatchedAdministrationPermissionFromAuth0(accessToken || "")
      setFirstMatchingAdminPermission(matchingData)
    }
    if (isAuthenticated) {
      show()
    }
  }, [isAuthenticated])

  return (
    <ul className={ props.className }>
      <CustomNavLink exact={ true } url={ '/' } linkText={ 'EFOY FUEL CELLS' } { ...props } />
      <CustomNavLink url={ '/user_account' } linkText={ 'USER ACCOUNT' } { ...props } />
      <CustomNavLink url={ '/groups' } linkText={ 'GROUPS' } { ...props } />
      <CustomNavLink url={ '/tags' } linkText={ 'TAGS' } dataCY="tags-link" { ...props } />
      {
        /** If user has enough permission then display a link admin area. */
        firstMatchingAdminPermission
        ? <CustomNavLink
            isActive={(match, location) => location && location.pathname ? location.pathname.indexOf('/admin') === 0 : false}
            url={ `/admin/${firstMatchingAdminPermission.split(':')[0]}` }
            exact={false}
            dataCY='nav-link-admin'
            linkText={ 'ADMINISTRATION' }
            { ...props } />
        : null
      }
    </ul>
  )
}
