import { getTime, format } from 'date-fns'
import { MyChartProps, InputObject, OutputObject } from '../types/types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { green, blue } from '@mui/material/colors'

// custom colors for the charts
const customColor = ['#d53808', '#b7837b', '#3db7a9', '#d8e35c', '#dc952f', '#c662ed', '#1480d3', '#ebae4f', '#79f1d9', '#b52247']
// Get a color and pop it.
export const getColor = (unit) => {
  if (unit === 'celsius') {
    // get a random green or blue color
    const color = [green[500], green[700], green[900], blue[500], blue[700], blue[900]][Math.floor(Math.random() * 2)]
    return color
  }
  // get a random color
  if (customColor.length > 0) {
    return customColor.pop()
  }
}

/*
  @func preprocessData
  @desc Preprocess the data for a chart
  @param {MyChartProps} serie
*/

const skipNullValues = (serie) => {
  const processedData: number[] = []
  const oneHour = 60 * 60 * 1000 // or whatever the resolution is
  const sharpSkip = {
    ids: ['avgFillLevel'],
    nullableSkip: 4 * oneHour,
    default: oneHour
  }
  if (!serie?.data || serie?.data?.length === 0) return processedData

  // delete every null value to insert later null values based on sharpSkip settings
  sharpSkip.ids.includes(serie.id) && (serie.data = serie.data.filter((ele) => ele[1] !== null))

  const nullableSkip = sharpSkip.ids.includes(serie.id) ? sharpSkip.nullableSkip : sharpSkip.default
  serie?.data?.forEach(([timestamp], idx, arr) => {
    // check if the timestamp and the next series.data[idx + 1] are one day apart
    if (idx < serie?.data?.length - 1 && arr[idx + 1][0] - timestamp > nullableSkip) {
      serie.data.splice(idx + 1, 0, [serie.data[idx][0] + nullableSkip, null])
    }
  })

  return processedData
}

/*
  @func processHistoryData
  @desc Process the data for the history chart
  @param {any} obj
  @return {MyChartProps[]}
*/
export const processHistoryData = (obj: any) => {
  let styleSerieColor = 0
  const result: MyChartProps[] = []
  const avgOutputPowers: MyChartProps = {
    id: 'avgOutputPower',
    name: 'Power Output (W)',
    type: 'spline',
    data: obj.avgOutputPowers?.map((ele, idx) => [obj.timestamps[idx], !ele ? ele : twoDecimalPlaces(ele)]),
    yAxis: 0,
    color: `var(--series-${styleSerieColor++}-color)`,
    visible: true,
    marker: {
      enabled: true, // ensure markers are shown for isolated points
      radius: 3 // larger marker size
    }
  }
  const avgOutputCurrents: MyChartProps = {
    id: 'avgOutputCurrent',
    name: 'Charging Current (A)',
    type: 'spline',
    data: obj.avgOutputCurrents?.map((ele, idx) => [obj.timestamps[idx], !ele ? ele : twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: `var(--series-${styleSerieColor++}-color)`,
    visible: true,
    marker: {
      enabled: true, // ensure markers are shown for isolated points
      radius: 3 // larger marker size
    }
  }
  const avgFillLevels: MyChartProps = {
    id: 'avgFillLevel',
    name: 'Remaining Total Fuel (%)',
    type: 'spline',
    data: obj.avgFillLevels?.map((ele, idx) => [obj.timestamps[idx], !ele ? ele : twoDecimalPlaces(ele)]),
    yAxis: 0,
    color: `var(--series-${styleSerieColor++}-color)`,
    visible: true,
    marker: {
      enabled: true, // ensure markers are shown for isolated points
      radius: 3 // larger marker size
    }
  }
  const avgBatteryVoltages: MyChartProps = {
    id: 'avgBatteryVoltage',
    name: 'Battery Voltage (V)',
    type: 'spline',
    data: obj.avgBatteryVoltages?.map((ele, idx) => [obj.timestamps[idx], !ele ? ele : twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: `var(--series-${styleSerieColor++}-color)`,
    visible: true,
    marker: {
      enabled: true, // ensure markers are shown for isolated points
      radius: 3 // larger marker size
    }
  }
  const avgAmbientTemperatures: MyChartProps = {
    id: 'avgAmbientTemperature',
    name: 'Ambient Temperature (C)',
    type: 'spline',
    data: obj.avgAmbientTemperatures?.map((ele, idx) => [obj.timestamps[idx], !ele ? ele : twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: `var(--series-${styleSerieColor++}-color)`,
    visible: true,
    marker: {
      enabled: true, // ensure markers are shown for isolated points
      radius: 3 // larger marker size
    }
  }

  result.push(avgOutputPowers, avgOutputCurrents, avgFillLevels, avgBatteryVoltages, avgAmbientTemperatures)

  result.forEach((serie) => {
    skipNullValues(serie)
  })

  return result
}

/*
@func processMultiSenseArray
@desc Process the data for the multi sense chart
@param {InputObject} inputObject
@return {OutputObject}
*/
export const processMultiSenseArray = (inputObject: InputObject, variant: string): OutputObject => {
  // in case for mock so you can adjust the graph appearance
  // const mock = mockedResponse
  if (!inputObject) {
    // Handle null or undefined inputObject
    return [[], []]
  }

  const processedMultisenseArray: any = []
  const closedPortsArray: any = []
  const allTimestamps: number[] = []

  // should be inputObject, but you can use mock.ports for testing
  Object.entries(inputObject).forEach(([name, items]) => {
    // pust the IOs only if the variant is not equal to "none" and "multi_sense_4"
    const isIO = name.includes('io')
    const variantExcludingIos = ['none', 'multi_sense_4'].includes(variant)
    if (!items || items.length === 0) {
      // if variant is equal to none || multise_sense_4 then get rid of the IOs in the unconfigured ports
      if (!isIO && variantExcludingIos) {
        closedPortsArray.push({
          name: name,
          unconfigured: true
        })
      }

      if (!variantExcludingIos) {
        closedPortsArray.push({
          name: name,
          unconfigured: true
        })
      }

      return
    }

    items.forEach((item) => {
      const { data, config } = item
      const seperateData = <T>(commonValues: T[][]): [T[], T[]] => {
        const timestamps = commonValues.reduce((acc, curr) => {
          return [...acc, curr[0]]
        }, [])
        const values = commonValues.reduce((acc, curr) => {
          return [...acc, curr[1]]
        }, [])
        return [timestamps, values]
      }
      // data now is of this format [[timestamp, value],...]
      const [timestamps, values]: any[][] = seperateData(data)

      if (!data.length) {
        return
      }

      allTimestamps.push(...timestamps)
      processedMultisenseArray.push({
        data: values.map((val, idx) => {
          return [timestamps[idx], typeof val === 'boolean' ? val : twoDecimalPlaces(val)]
        }),
        id: `${Math.random().toString(26).slice(2)}-${name}`,
        name: `${config.function.at(0)?.toUpperCase()}${config.function.slice(1)} ${name.toLocaleUpperCase()} (${config.unit ?? 'ON'})`,
        yAxis: config.unit !== undefined ? 0 : 1,
        color: getColor(config.unit),
        timestamps: timestamps,
        alignedData: values.map((val, idx) => {
          return [timestamps[idx], typeof val === 'boolean' ? val : twoDecimalPlaces(val)]
        }),
        visible: true,
        marker: {
          enabled: true, // ensure markers are shown for isolated points
          radius: 3 // larger marker size
        }
      })
    })
  })

  // Renaming the series if there is two series coming from the same sensor (for example a1 and a1)
  const processNames = (processedArr: { id: string; name: string; data: number[] }[]) => {
    // merge two series with the same name and add the values of the two series
    const result = processedArr.reduce((acc: { id: string; name: string; data: number[] }[], curr) => {
      const { name } = curr
      const existing: any = acc.find((item) => item.name === name)
      if (existing) {
        // get the timestamp of the second after the last timestamp of the existing series
        // to discuss with Jakob
        const lastTimestamp = existing.data[existing.data.length - 1][0]
        // add one second from the the last timestamp of the existing series
        const newTimestamp = lastTimestamp + 1000
        // add the new timestamp to the existing series
        existing.data.push([newTimestamp, null])
        curr.data.forEach((item) => {
          existing.data.push(item)
        })
      } else {
        acc.push(curr)
      }
      return acc
    }, [])

    return result
  }

  const doorsTableSeries = (processedArr: { id: string; name: string; data: any[]; alignedData?: any[] }[]) => {
    let temp: any[] = []

    // check if all values of the series is either ON or null and the name is ending with (ON) and form an array with these which will be used in the doors table
    const filteredArray = processedArr.filter((element) => {
      // eslint-disable-next-line
      const allValues = element.data.every(([timestamp, value]: [number, boolean]) => typeof value === 'boolean')
      const name = element.name.endsWith('(ON)')
      return allValues && name
    })

    // do get the filtered series that are ending with (ON) and push them to the temp array
    temp = filteredArray.map((ele, idx) => {
      return {
        ...ele,
        yAxis: 0,
        linecap: 'square',
        data:
          ele.data.length > 0
            ? ele.data.map(([timestamp, value]) => {
                // if data[1] is ON then give it the value of idw
                return typeof value === 'boolean' && !!value ? [timestamp, idx + 1] : [timestamp, value]
              })
            : []
      }
    })

    // regroup the series that has the same IO number
    temp = temp.reduce((acc, curr) => {
      const IO = curr.name.split(' ')[1]
      const Func = curr.name.split(' ')[0]
      const found = acc.findIndex((item) => item.name.split(' ')[1] === IO)
      const foundValues = found > -1 ? acc[found].data.find((item) => item[1] > 0) : []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [foundYAxisTmstm, foundYAxisValue] = foundValues?.length ? foundValues : [null, null] // if foundValues is null then assign null to both foundYAxisTmstm and foundYAxisValue
      if (found > -1) {
        const newData = curr.data.map((item) => (item[1] ? [item[0], foundYAxisValue] : [item[0], null]))
        return [...acc, { ...curr, name: `${Func} ${IO}`, data: newData }]
      }

      return [...acc, { ...curr, name: `${Func} ${IO}` }]
    }, [])
    return temp
  }

  const series = processNames(processedMultisenseArray)
  const periods = doorsTableSeries(series)

  // Getting the names of the periods
  let periodNames = periods.reduce((acc, curr) => {
    if (!acc.includes(curr.name)) {
      return [...acc, curr.name]
    }
    return acc
  }, [])

  // filter out the series that are not periods
  const seriesWithoutIOs = series.filter((element) => !periodNames.includes(`${element.name.split(' ')[0]} ${element.name.split(' ')[1]}`))
  // clean up of names in the periodNames array and turning it into a set, just to Get an example of [IO1, IO2, IO3]
  periodNames = [...new Set(periodNames.map((period: any) => period.slice(0, -5).split(' ')[1]))]
  /*
    This section is for applying extra changes to series if needed
  */
  seriesWithoutIOs.forEach((element) => {
    skipNullValues(element)
    checkNameAndApplyChange(element)
  })
  periods.forEach((element) => {
    skipNullValues(element)
    checkNameAndApplyChange(element)
  })

  return [seriesWithoutIOs, periods, closedPortsArray]
}

export const twoDecimalPlaces = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const refactorTimestampGraph = (array: string[]) => {
  const result = array.map((timeStamp) => new Intl.DateTimeFormat('de-DE', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).format(new Date(timeStamp)))
  return result
}

export const refactorTimestampGraphinMilliSeconds = (array: string[]) => {
  // Return minutes and seconds and millisecuonds as well
  const result = array.map((timeStamp) =>
    new Intl.DateTimeFormat('de-DE', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(new Date(timeStamp))
  )
  return result
}

export const getTimeStamp = (timestamps: any) => {
  return timestamps.sort((a: string, b: string) => getTime(new Date(a)) - getTime(new Date(b)))
}

export const findErrorValueBasedOnTimeStamp = (array: string[], timeStamp: string) => {
  let index = 0
  if (array.length === 0) return null
  for (let i = 0; i < array.length; i++) {
    if (timeStamp > array[i]) {
      index = i
    }
  }
  const timeStampMinutes = new Date(timeStamp).getMinutes()
  // find the percentage of minutes in the hour
  const percentage = timeStampMinutes / 60
  // return the error value of the timestamp
  return index + percentage
}

/*
  This function is used to render the titles from left and right with the styles
  @param arrayOfSeries: array of series
  @param Axis: 0 for left side and 1 for right side
*/
export const renderTitleWithStyles = (arrayOfSeries: any[], Axis: number) => {
  const rightSideYAxis = arrayOfSeries.filter((element) => element.yAxis === 1)
  const leftSideYAxis = arrayOfSeries.filter((element) => element.yAxis === 0)

  let leftSideTitle = ''
  let rightSideTitle = ''

  leftSideYAxis.forEach((element) => {
    leftSideTitle += `<span class="highcharts-XAxis-title-${arrayOfSeries.indexOf(element)}">${element.name}</span>   `
  })

  rightSideYAxis.forEach((element) => {
    rightSideTitle += `<span class="highcharts-XAxis-title-${arrayOfSeries.indexOf(element)}">${element.name}</span>  `
  })

  // Based on Axis return the title
  return Axis === 0 ? leftSideTitle : rightSideTitle
}

export const zoomCharts = (e, current: HighchartsReact.RefObject | null) => {
  const AllLineCharts = Highcharts.charts.filter((c) => c?.options?.chart?.type === 'spline')
  if (current) {
    // Get type by chart?.options.chart?.type
    const chart = current.chart
    const index = AllLineCharts.findIndex((c) => c === chart)
    if (AllLineCharts.length > 1) {
      const firstChartExtremes = chart?.xAxis[0].getExtremes() // min and max for zoom
      // Accessing the extremes
      const min = firstChartExtremes.min
      const max = firstChartExtremes.max

      // Calculating the difference between min and max, this might be used later
      // const difference = new Date(max).getTime() - new Date(min).getTime()
      // to rethink about the 24 hour condition later // Code to be added

      // All charts are synchronized except the one zoomed manually
      if (firstChartExtremes?.min) {
        AllLineCharts.forEach((c, i) => {
          if (i !== index) {
            c?.xAxis[0].setExtremes(min, max)
          }
        })
      }
    }
  }
}

/*
  @func getEvent
  @desc Return an event to the chart based on its type
  @returns ArrayOfEvents
  @param {event} any
*/

export const getEvents = (events: any, devicetype: string) => {
  // based on event.eventClass return the color of the line
  // event can be "error", "warning" or newly added "fm_active_port"
  const colorEvent = (eventType) => {
    return eventType === 'error' ? 'rgb(250, 88, 98)' : eventType === 'warning' ? 'rgb(255, 175, 79)' : 'rgb(255,0,255)'
  }

  if (events?.lenght <= 0) {
    return []
  }
  const newEvents = events.map((event) => {
    return {
      color: colorEvent(event.eventClass), // line color
      value: new Date(event.timestamp).getTime(), // x-axis value where the line will appear
      width: 2, // line width,
      zIndex: 1000,
      label: {
        useHTML: true, // allows HTML tags in the label text
        className: 'highcharts-mouseover-label',
        style: {
          // color: 'var(--color-lightest)', // label text color,
          fontSize: '13px',
          cursor: 'pointer',
          background: colorEvent(event.eventClass),
          padding: '1px',
          fontFamily: 'open-sans-regular',
          border: '1px solid white',
          borderRadius: '4px',
          textDecoration: 'none',
          rotation: 45,
          zIndex: 0
        },
        x: -12, // Adjust the horizontal position if needed
        formatter: () => {
          if (event?.eventClass === 'fm_active_port') {
            return `<span class="highcharts-tooltip-event">Port ${event.activePort}</span>`
          }
          const majorEventCodeStr = event?.major?.toString()
          const minorEventCodeStr = event?.minor?.toString()
          const formulatedError = () => {
            const maxLength = 3
            const majorEventCode = majorEventCodeStr.padStart(maxLength, '0')
            const minorEventCode = minorEventCodeStr.padStart(maxLength, '0')
            return `${majorEventCode}.${minorEventCode}`
          }
          // Use HTML and wrap the label text in an anchor tag with the desired hyperlink
          return `<a target="_blank" class="highcharts-tooltip-event" href="https://www.efoy-pro.com/en/service/servicetool/?product=${devicetype}&errorcode=${formulatedError()}">${formulatedError()}</a>`
        }
      }
    }
  })
  return newEvents
}

export const findOutEventTypeBasedOnHoverColor = (element: HTMLElement, plotLine: any) => {
  const errorBackground = 'rgb(250, 88, 98)'
  // to use in case of future event type
  // eslint-disable-next-line
  const warningBackground = 'rgb(255, 175, 79)'
  const { style } = element
  const { value } = plotLine
  const backgroundColor = style.backgroundColor

  return {
    eventType: backgroundColor === errorBackground ? `Error` : `Warning`,
    color: backgroundColor === errorBackground ? errorBackground : warningBackground,
    date: format(value, 'dd.MM.yy - HH:mm')
  }
}

type serieItem = {
  id: string
  name: string
  data: number[]
}

export const checkNameAndApplyChange = (seriesItem: serieItem) => {
  // if name contains efoy with lowercase, we change it to UpperCase
  if (seriesItem?.name && typeof seriesItem?.name === 'string' && seriesItem?.name.toLowerCase().includes('efoy')) {
    seriesItem.name = seriesItem.name.replace(/efoy/gi, 'EFOY')
  }
}
